<script>
// Cool way to render Vue components from HTML Strings
// https://medium.com/haiiro-io/compile-markdown-as-vue-template-on-nuxt-js-1c606c15731c
import VueWithCompiler from "vue/dist/vue.esm";
import draggable from "vuedraggable";
import Vue from "vue";
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

// Use vuetify for this sub component!
Vue.use(Vuetify);

export default {

	components: {
		draggable,
	},
	props: {
		html: {
			type: String,
			default: "",
		},
		responses: {
			type: Array,
		},
		words: {
			type: Array
		},
	},
	data() {
		return {
			templateRender: undefined,
		};
	},
	watch: {
		html: {
			handler() {
				this.updateRender();
			},
			immediate: true,
		},
		responses: {
			handler() {
				this.updateRender();
			},
			immediate: true,
			deep: true,
		},
		words: {
			handler() {
				this.updateRender();
			},
			immediate: true,
			deep: true,
		}
	},
	created() {
		this.updateRender();
	},
	methods: {
		updateRender() {
			let compiled = VueWithCompiler.compile("<div>" + this.html + "</div>");
			this.templateRender = compiled.render;
			this.$options.staticRenderFns = [];

			for (const staticRenderFunction of compiled.staticRenderFns) {
				this.$options.staticRenderFns.push(staticRenderFunction);
			}
		},
		revert(arr_vals) {
			if (arr_vals.length === 2) {
				this.words.push(arr_vals[1]);
				arr_vals.splice(1);
			}
		},
	},
	render() {
		return this.templateRender();
	},
};
</script>